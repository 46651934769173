import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { useRoutePriority } from "@hooks/useRouteIndex"
import { ComponentProps, PropsWithChildren, useLayoutEffect, useState } from "react"
import { Flipped } from "react-flip-toolkit"

type Props = PropsWithChildren<Omit<ComponentProps<typeof Flipped>, "children">>

export function RouteFlipped(props: Props) {
  const isFront = useRoutePriority(props.flipId as string)

  let [show, setShow] = useState(isFront)

  if (env.client) {
    useLayoutEffect(() => {
      setShow(isFront)
    }, [isFront])
  }

  if (isFront) {
    show = isFront
  }

  // show = isFront

  return (
    <Flipped {...props} flipId={show ? props.flipId : undefined} onStart={(el) => el.style.setProperty("z-index", "100")} onComplete={(el) => el.style.setProperty("z-index", "")}>
      <div style={{ opacity: show ? 1 : 0 }} className="will-change-transform transform-gpu relative">
        {props.children}
      </div>
    </Flipped>
  )
}

type ImageProps = ComponentProps<typeof ResponsiveImage> & {
  flipId: string
  flip?: Omit<ComponentProps<typeof Flipped>, "flipId">
}

export function RouteFlippedResponsiveImage({ flipId, flip, ...props }: ImageProps) {
  return (
    <RouteFlipped flipId={flipId} {...flip}>
      <ResponsiveImage fadeOnLoad={false} {...props} />
    </RouteFlipped>
  )
}
